import React from 'react';
import styled from 'styled-components';
import { MenuItemOuter, ClickElement } from '../MenuItem';
import { LabelComponentProps } from '../types';

export type Props = LabelComponentProps;

const MobileLabel:React.FC<Props> = ({
  hasChildren,
  isChild,
  text,
  // mobileBreakpointName,
}) => {
  return (
    <MobileLabelOuter
      className={[
        isChild ? 'is-child' : 'not-child',
      ].join(' ')}
    >
      {text}
      {hasChildren && ' +'}
    </MobileLabelOuter>
  );
};
export default MobileLabel;

const MobileLabelOuter = styled.div`
  margin-bottom: 20px;

  ${MenuItemOuter}.active > ${ClickElement} & {
    cursor: default;
    color: white;
  }
  ${MenuItemOuter}.not-child > ${ClickElement} & {
    cursor: pointer;
  }
`;
