import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setScrollingLocked } from 'src/store/features/utility/utilitySlice';
import Container from 'src/components/Container';
import PerfectScrollbar, { ReactPerfectScrollbar } from 'src/lib/components/PerfectScrollbar';
import NavBase from 'components/NavMenu/NavBase';
import { MobileDesktopNavSharedProps } from 'components/NavMenu/types';
import Dropdown from 'components/Dropdown';
import { BreakpointKey } from 'src/styles/theme';
import VisibilityHandler from './VisibilityHandler';
import MobileLabel from './MobileLabel';


export type Props = MobileDesktopNavSharedProps;

const MobileNav:React.FC<Props> = ({ navItems, mobileBreakpointName }) => {
  const scrollBarsRef = React.useRef<ReactPerfectScrollbar>(null);
  const theme = React.useContext(ThemeContext);
  const { isVisible: isVisibleNav } = useAppSelector((state) => state.navMain);
  const dispatch = useAppDispatch();
  const lockScrolling = () => dispatch(setScrollingLocked(true));
  const unlockScrolling = React.useCallback(() => {
    dispatch(setScrollingLocked(false));
  }, [dispatch]);

  const updateScrollbars = () => {
    scrollBarsRef.current?.updateScroll();
  };

  const SubNavContainerComponent = React.useCallback((props) => (
    <DropdownStyled
      {...props}
      onClose={updateScrollbars}
      onOpen={updateScrollbars}
    />
  ), []);

  React.useEffect(() => () => { // unmount
    if (isVisibleNav) unlockScrolling();
  }, [isVisibleNav, unlockScrolling]);

  return (
    <VisibilityHandler
      onEntered={lockScrolling}
      onExited={unlockScrolling}
    >
      <MobileNavOuter mobileBreakpointName={mobileBreakpointName}>
        <PerfectScrollbarStyled
          color={theme.palette.primary}
          refOverride={scrollBarsRef}
        >
          <ContainerStyled>
            <StyleWrap>
              <NavBase
                navItems={navItems}
                mobileBreakpointName={mobileBreakpointName}
                labelComponent={MobileLabel}
                subNavContainerComponent={SubNavContainerComponent}
              />
            </StyleWrap>
          </ContainerStyled>
        </PerfectScrollbarStyled>
      </MobileNavOuter>
    </VisibilityHandler>
  );
};
export default MobileNav;

const MobileNavOuter = styled.div<{ mobileBreakpointName: BreakpointKey }>`
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.palette.interactive};
  ${(p) => p.theme.breakpoints.up(p.mobileBreakpointName)} {
    display: none;
  } // to minimise Google CLS metric during js page load
`;
const DropdownStyled = styled(Dropdown)`
  padding-left: 20px;
  transition: height, margin, filter, opacity;
  transition-duration: 0.3s;
  &.inactive {
    /* filter: blur(2px); */
    opacity: 0;
  }
  &.active {
    margin: -10px 0 20px;
  }
`;
const PerfectScrollbarStyled = styled(PerfectScrollbar)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
const StyleWrap = styled.div`
  color: ${(p) => p.theme.palette.primary};
  font-weight: bold;
  font-size: 3rem;
`;
const ContainerStyled = styled(Container)`
  ${({ theme }) => theme.breakpoints.up('phoneWide')} {
    padding-top: 20px;
  }
`;
