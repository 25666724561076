import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setVisibility } from 'store/features/nav/main/navMainSlice';

interface Props {
  timeout?: number,
  onEnter?: () => void,
  onExit?: () => void,
  onEntering?: () => void,
  onExiting?: () => void,
  onEntered?: () => void,
  onExited?: () => void,
}

const VisibilityHandler:React.FC<Props> = ({
  children,
  timeout = 300,
  onEnter = () => {},
  onExit = () => {},
  onEntering = () => {},
  onExiting = () => {},
  onEntered = () => {},
  onExited = () => {},
}) => {
  const isVisible = useAppSelector((state) => state.navMain.isVisible);
  const dispatch = useAppDispatch();
  const hideNav = () => dispatch(setVisibility(false));

  // unmount
  React.useEffect(() => () => {
    hideNav();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <VisibilityHandlerOuter>
      <CSSTransition
        in={isVisible}
        timeout={timeout}
        mountOnEnter
        unmountOnExit
        onEnter={onEnter}
        onExit={onExit}
        onEntering={onEntering}
        onExiting={onExiting}
        onEntered={onEntered}
        onExited={onExited}
      >
        <TransitionElement timeout={timeout}>
          {children}
        </TransitionElement>
      </CSSTransition>
    </VisibilityHandlerOuter>
  );
};
export default VisibilityHandler;

const VisibilityHandlerOuter = styled.div`
  position: relative;
  z-index: 1;
`;
const TransitionElement = styled.div<Pick<Props, 'timeout'>>`
  &.enter {
    opacity: 0;
  }
  &.enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`;
